import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser, faTag, faComment } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

import { translate, TranslationFunction } from 'react-i18next';
import { formatBlogDate } from '../../misc/tools';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import BreadCrumbs from '../../components/BreadCrumbs';
import DisqusCommentCount from '../../components/DisqusCommentCount';
import BlogLayout from '../../layouts/BlogLayout';
import { Consumer as BlogConsumer } from '../../context/blog';
import { i18n } from 'i18next';

interface IProps {
  i18n: i18n,
  t: TranslationFunction,
}

function Index({ i18n, t }: IProps) {
  return (
    <BlogLayout>
      <div className="main-wrapper">
        <Helmet title="Mijn Presta | Blog"/>
        <Header/>
        <BreadCrumbs breadcrumbs={[
          {
            text: 'Home',
            href: {
              nl: '',
              en: '',
            }
          },
          {
            text: 'Blog',
          }
        ]}/>
        <section id="main" className="wrapper">
          <div className="inner">
            <div className="blog-posts">
              <h1 className="major">{t('Blog')}</h1>
              <BlogConsumer>
                {(posts: Array<any>) => posts
                  .map(({ node: post }: any) => (
                      <div className="blog-post-preview" key={post.id}>
                        <Link to={post.frontmatter.path} className="image fit" style={{ maxHeight: '20em', overflowY: 'hidden' }}>
                          <img src={post.frontmatter.scene} alt=""/>
                        </Link>
                        <h2>
                          <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                        </h2>
                        <h3 style={{ fontSize: '0.8em' }}>
                          <span>{t('Posted By')}</span>&nbsp;
                          <FontAwesomeIcon icon={faUser}/> <span>{post.frontmatter.author}</span>&nbsp;
                          <FontAwesomeIcon icon={faCalendar}/> <span>{formatBlogDate(post.frontmatter.date, i18n.language)}</span>&nbsp;
                          <FontAwesomeIcon icon={faComment}/> <DisqusCommentCount shortname="mijnpresta" prefix={post.frontmatter.path} identifier={post.frontmatter.disqus}/>&nbsp;
                          <FontAwesomeIcon icon={faTag}/> <span>{post.frontmatter.tags.map((tag: string) => <Link to={`/${i18n.language}/tags/${_.kebabCase(tag)}/`} key={tag}>{tag} </Link>)}</span>&nbsp;
                        </h3>
                        <p>{post.excerpt}</p>
                      </div>
                    )
                  )}
              </BlogConsumer>
            </div>
          </div>
        </section>
        <Footer/>
      </div>
    </BlogLayout>
  );
}

export default translate()(Index);
