import React, { ReactChildren } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { translate } from 'react-i18next';
import _ from 'lodash';

import { Provider as BlogProvider } from '../context/blog';

import '../assets/sass/main.scss';
import '../components/i18n';

import { I18nextProvider } from 'react-i18next';
import { i18n } from 'i18next';

interface IProps {
  children?: JSX.Element,
  data?: any,
  i18n?: i18n,
}

export interface IBlogNode {
  html: string,
  excerpt: string,
  id: string,
  frontmatter: {
    title: string,
    author: string,
    tags: Array<string>,
    scene: string,
    date: string,
    path: string,
    lang: string,
    disqus: string,
    alternative: string,
    alternativeLang: string,
  }
}

const BlogLayout = ({ children, data, i18n }: IProps) => {
  let canonical: string;
  if (typeof window !== 'undefined') {
    canonical = _.get(window, 'location.pathname');
    if (window.location.pathname.substring(0, 4) !== `/${i18n.language}/`) {
      canonical = `/${i18n.language}/${canonical}`;
    }
    if (canonical.slice(-1) !== '/') {
      canonical += '/';
    }
  }

  return (
    <StaticQuery query={graphql`
fragment markdownFields on MarkdownRemark {
  excerpt(pruneLength: 250)
  id
  frontmatter {
    title
    author
    tags
    scene
    date(formatString: "YYYY-MM-DD")
    path
    lang
    disqus
  }
}

query BlogQuery {
  en: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {lang: {eq: "en"}}}) {
    edges {
      node {
        ...markdownFields
      }
    }
  }
  nl: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {lang: {eq: "nl"}}}) {
    edges {
      node {
        ...markdownFields
      }
    }
  }
}
    `}
     render={data => (
       <BlogProvider value={data[i18n.language].edges}>
         <Helmet
           title="Blog"
           meta={[
             { name: 'description', content: 'Mijn Presta' },
           ]}
         >
           <link rel="canonical" href={canonical}/>
         </Helmet>
         <I18nextProvider i18n={i18n}>
           <div>
             {children}
           </div>
         </I18nextProvider>
       </BlogProvider>
     )}
    />
  );
};

export default translate()(BlogLayout);
